<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 z-[150] bg-black opacity-50"
    @click="closePopup"
  ></div>
  <section
    aria-label="navigation"
    class="popup fixed !bottom-0 left-0 right-0 z-[200] hidden rounded-t-[24px] bg-ui_light_grey px-[20px] py-[16px]"
    :class="{ 'move-up !block': isOpen }"
  >
    <div class="flex flex-col">
      <div class="flex">
        <p class="w-full pl-8 pt-5 text-center text-2xl">
          {{ title }}
        </p>
        <div
          class="flex cursor-pointer self-start rounded-full bg-white p-2.5 transition-all"
          @click="closePopup"
        >
          <UiIcon
            fill="black"
            name="close"
            size="xs"
          />
        </div>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script setup>
import { onMounted, watch } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const closePopup = () => {
  emit('close');
};

const setPadding = () => {
  const html = document.documentElement;
  const paddingSize = window.innerWidth - html.clientWidth;
  if (paddingSize) {
    html.style.paddingRight = paddingSize + 'px';
  }
};

onMounted(() => {
  const html = document.documentElement;
  html.style.paddingRight = '';
});

let scrollPosition = 0;
watch(
  () => props.isOpen,
  (newValue) => {
    const html = document.documentElement;
    if (newValue) {
      scrollPosition = window.scrollY;
      setPadding();
      html.style.top = `-${scrollPosition}px`;
      html.classList.add('overflow-hidden');
    } else {
      html.classList.remove('overflow-hidden');
      html.style.paddingRight = '';
      window.scrollTo(0, scrollPosition);
      html.style.top = '';
    }
  },
);
</script>

<style scoped>
.popup {
  -webkit-overflow-scrolling: touch;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.move-up {
  animation: moveUp 0.2s ease-out forwards;
}

@keyframes moveUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
